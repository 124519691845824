<template>
  <v-container fluid>
    <h1>GitHub</h1>
    <h3>
      Add a link to your github repo if you want it to be part of a very cool
      visualization we will be making!
    </h3>
    <v-form ref="form" v-model="valid">
      <v-layout row wrap>
        <div class="mx-3" style="font-size:2.6em">
          github.com/
        </div>
        <v-flex ml-3 xs6 md3>
          <v-text-field
            v-model="gitUser"
            label="username"
            ref="github_in"
            required
            :rules="rules"
            lazy-validation
          ></v-text-field>
        </v-flex>
        <div class="mx-3" style="font-size:2.6em">
          /
        </div>
        <v-flex ml-3 xs6 md3>
          <v-text-field
            v-model="gitRepo"
            label="repo"
            ref="github_repo"
            :rules="rules"
            required
            lazy-validation
          ></v-text-field>
        </v-flex>
        <v-btn
          large
          class="ml-2"
          @click="submit"
          :loading="loading"
          :disabled="!valid"
        >
          submit
        </v-btn>
      </v-layout>
    </v-form>
    <v-alert
      border="left"
      dismissible
      my-0
      py-0
      v-model="failedDialog"
      type="warning"
      transition="scale-transition"
    >
      Make sure that you entered a public, valid repo
    </v-alert>
    <v-row wrap>
      <v-col v-for="r in repos" :key="r.id" cols>
        <Repo :lines="r.lines" :name="r.name" :id="r.id" />
      </v-col>
    </v-row>
    <v-layout>
      <v-spacer></v-spacer>
      <v-btn @click="$fiery.more(repos)">
        Load More
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import db from "../firebase";
import Repo from "../components/Repo";

export default {
  fiery: true,
  components: { Repo },
  data() {
    return {
      valid: false,
      loading: false,
      rules: [
        v => !!v || "Cannot be empty",
        v => !v.includes("/") || "cannot contain '/'"
      ],
      gitUser: "",
      gitRepo: "",
      gitRules: [v => !!v || "Link is required"],
      failedDialog: false,
      repos: this.$fiery(db.collection("repos"), {
        query: q => q.orderBy("updated_at", "desc"),
        stream: true,
        streamInitial: 4,
        streamMore: 4,
        key: "id",
        exclude: ["id"]
      })
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      const data = this;
      data.loading = true;
      if (data.gitUser && data.gitRepo) {
        const id =
          data.gitUser.toLowerCase() + "+" + data.gitRepo.toLowerCase();
        const repo = data.repos.filter(r => r.key === id);
        if (repo.length > 0) {
          // update maybe
          fetch("https://www.doc.ic.ac.uk/~docsoc/cloc/cloc.cgi?" + id)
            .then(res => res.json())
            .then(json => {
              if (json) {
                repo[0].lines = JSON.stringify(json);
                repo[0].updated_at = new Date();
                data.$fiery.update(repo[0]).then(() => {
                  data.gitRepo = "";
                  data.gitUser = "";
                  data.loading = false;
                });
              }
            });
        } else {
          // Create new
          fetch("https://www.doc.ic.ac.uk/~docsoc/cloc/cloc.cgi?" + id)
            .then(res => res.json())
            .then(json => {
              if (json) {
                data.$fiery.create(data.repos, {
                  name: `${data.gitUser.toLowerCase()}/${data.gitRepo.toLowerCase()}`,
                  lines: JSON.stringify(json),
                  updated_at: new Date(),
                  key: id
                });
                data.gitRepo = "";
                data.gitUser = "";
                data.loading = false;
              }
            })
            .catch(err => {
              data.failedDialog = true;
              data.loading = false;
            });
        }
      }
    }
  }
};
</script>
