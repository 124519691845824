<template>
  <v-card
    :href="`https://github.com/${name}`"
    target="_blank"
    width="400"
    height="470"
    :loading="loading"
  >
    <v-card-title>
      {{ name }}
    </v-card-title>
    <div :id="'vis' + id"></div>
  </v-card>
</template>

<script>
/* eslint-disable */
import fetch from "node-fetch";

export default {
  props: ["lines", "name", "id"],
  data: () => ({
    myFlower: undefined,
    loading: false
  }),
  watch: {
    lines: function(val) {
      let myFlower = new CodeFlower("#vis" + this.id, 400, 400);
      myFlower.update(JSON.parse(val));
      this.loading = false;
    }
  },
  mounted() {
    this.loading = true;
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute("src", "/javascripts/CodeFlower.js");
    document.head.appendChild(ckeditor);

    let myFlower = new CodeFlower("#vis" + this.id, 400, 400);
    myFlower.update(JSON.parse(this.lines));
    this.loading = false;
  }
};
</script>

<style>
circle.node {
  cursor: pointer;
  stroke: #000;
  stroke-width: 0.5px;
}

circle.node.directory {
  stroke: #9ecae1;
  stroke-width: 2px;
}

circle.node.collapsed {
  stroke: #555;
}

.nodetext {
  fill: #252929;
  font-weight: bold;
  text-shadow: 0 0 0.2em white;
}

line.link {
  fill: none;
  stroke: #9ecae1;
  stroke-width: 1.5px;
}
</style>
